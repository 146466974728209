@if (columns && data) {
  <app-info-table styleClass="info-table" [columns]="columns" [data]="data">
    <ng-template capTemplate="floricodeFeatureValue.dynamic_label" let-item="item">
      @if ((item?.adjustable && editable) || (editable && (item | isCharacteristicAdjustable: adjustingProcessName))) {
        @if (item?.productFloricodeFeature?.floricode_feature_type_id; as selectedType) {
          <div class="value-wrapper">
            <cap-dropdown
              appendTo="body"
              [(ngModel)]="item.floricodeFeatureValue.id"
              [autoDisplayFirst]="false"
              [filter]="true"
              [showClear]="true"
              [options]="floricodeFeatureValuesList[selectedType]"
              [placeholder]="'button.select' | translate">
            </cap-dropdown>
            @if (item?.productFloricodeFeature?.floricodeFeatureType?.is_mandatory && !item?.floricodeFeatureValue.id) {
              <span class="error-text">
                <i class="fa fa-exclamation-triangle"></i>
                <span [innerHTML]="'dxp.floricodeFeatureValue.required' | translate"></span>
              </span>
            }
          </div>
        }
      } @else {
        {{ item?.floricodeFeatureValue?.dynamic_label | default }}
      }
    </ng-template>
  </app-info-table>
}
