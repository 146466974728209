import { Component, Input, OnInit } from '@angular/core';
import { GradingFloricodeFeature } from '@core/models/grading.model';
import { InfoTableColumn } from '@shared/modules/info-table/base/info-table-column.model';
import { TranslateService } from '@ngx-translate/core';
import { InfoTableColumnType } from '@shared/modules/info-table/base/info-table-column-type.enum';
import { MapItem } from '@capturum/ui/api';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-boxed-stock-characteristics',
  templateUrl: './boxed-stock-characteristics.component.html',
  styleUrls: ['./boxed-stock-characteristics.component.scss'],
})
export class BoxedStockCharacteristicsComponent implements OnInit {
  @Input() public editable = false;
  @Input() public data: GradingFloricodeFeature[];
  @Input() public adjustingProcessName: DisplayEntity | Entity;

  public columns: InfoTableColumn[];
  public floricodeFeatureValuesList: Record<string, MapItem[]> = {};

  constructor(private readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    this.declareColumns();
    this.extractFloricodeFeaturesData();
  }

  public extractFloricodeFeaturesData(): void {
    if (this.data) {
      this.data
        .sort((first, second) => {
          const firstIsAdjustable = first.gradingFloricodeFeatureAdjustingProcess?.length;
          const secondIsAdjustable = second.gradingFloricodeFeatureAdjustingProcess?.length;

          if (firstIsAdjustable && !secondIsAdjustable) {
            return -1;
          }

          return 0;
        })
        .map((item) => {
          const firstFieldValue = item?.productFloricodeFeature?.floricode_feature_type_id;

          this.floricodeFeatureValuesList[firstFieldValue] = item?.gradingFloricodeFeatureValues?.map((value) => {
            return {
              label: value.floricodeFeatureValue?.dynamic_label,
              value: value.floricodeFeatureValue?.id,
              key: value.floricodeFeatureValue?.floriday_key,
            };
          });
        });
    }
  }

  private declareColumns(): void {
    this.columns = [
      {
        field: 'productFloricodeFeature.floricodeFeatureType.dynamic_label',
        title: this.translateService.stream('dxp.boxed-stock.characteristic.label'),
      },
      {
        field: 'floricodeFeatureValue.dynamic_label',
        title: this.translateService.stream('dxp.boxed-stock.default_value.label'),
        type: InfoTableColumnType.Template,
      },
    ];
  }
}
