import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ResetGradedStock, SetGradedStock } from '@store/admin/graded-stock/graded-stock.actions';
import { GradedStockModel } from '@store/admin/graded-stock/graded-stock.model';

@State<GradedStockModel>({
  name: 'gradedStock',
})
@Injectable()
export class GradedStockState {
  @Action(SetGradedStock)
  public setGradedStock(ctx: StateContext<GradedStockModel>, { stock }: SetGradedStock): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      stock,
    });
  }

  @Action(ResetGradedStock)
  public resetGradedStock(ctx: StateContext<GradedStockModel>): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      stock: null,
    });
  }
}
