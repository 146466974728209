import { Pipe, PipeTransform } from '@angular/core';
import { GradingFloricodeFeature } from '@core/models/grading.model';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';

@Pipe({
  name: 'isCharacteristicAdjustable',
})
export class IsCharacteristicAdjustablePipe implements PipeTransform {
  public transform(value: GradingFloricodeFeature, adjustingProcessName: DisplayEntity | Entity): boolean {
    if (!value) {
      return null;
    }

    if (value?.gradingFloricodeFeatureAdjustingProcess) {
      return value.gradingFloricodeFeatureAdjustingProcess?.some((item) => {
        return item?.adjustingProcess?.value === adjustingProcessName;
      });
    }
  }
}
