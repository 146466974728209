import { GradingFloricodeFeature } from '@core/models/grading.model';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';
import { IsCharacteristicAdjustablePipe } from '../pipes/is-characteristic-adjustable.pipe';

export class BoxedCharacteristicsHelper {
  public static transformToBackend(characteristics: GradingFloricodeFeature[]): any {
    return characteristics?.map((characteristic) => {
      return {
        productFloricodeFeature: {
          floricodeFeatureType: { id: characteristic.productFloricodeFeature.floricode_feature_type_id },
          floricodeFeatureValue: { id: characteristic.floricodeFeatureValue?.id || null },
        },
      };
    });
  }

  public static checkIfMandatoryCharacteristicIsEmpty(
    characteristics: GradingFloricodeFeature[],
    adjustingProcessName: DisplayEntity | Entity,
  ): boolean {
    return characteristics
      ?.map((characteristic) => {
        const pipe = new IsCharacteristicAdjustablePipe();
        const currentAdjustingProcess = pipe.transform(characteristic, adjustingProcessName);

        return (
          characteristic.productFloricodeFeature?.floricodeFeatureType?.is_mandatory &&
          !characteristic.floricodeFeatureValue?.id &&
          currentAdjustingProcess
        );
      })
      .every((items) => {
        return !items;
      });
  }
}
